/* ======================================================================== */
/* 3. Header */
/* ======================================================================== */
class Header extends window.rhye.components.Base {
  constructor({
    scope,
    target
  }) {
		super({
			target,
			scope
		});
  }

  mount($el) {
    return new Promise((resolve) => {
		// prepare all the texts
      document.fonts.ready
        .then(() => window.rhye.components.SetText.splitText({
          target: $el.find('.js-split-text, .header__widget.split-text > *')
        }))
        .then(() => window.rhye.components.SetText.setLines({
          target: $el.find('.split-text[data-split-text-set="lines"]')
        }))
        .then(() => window.rhye.components.SetText.setWords({
          target: $el.find('.split-text[data-split-text-set="words"]')
        }))
        .then(() => window.rhye.components.SetText.setChars({
          target: $el.find('.split-text[data-split-text-set="chars"]')
        }))
        .then(() => resolve(true));
    });
  }

  run() {
    this.overlayBackground = this.$el.attr('data-arts-header-overlay-background');
    this.stickyTheme = this.$stickyHeader.attr('data-arts-header-sticky-theme');

    if (typeof this.stickyScene !== 'undefined') {
      this.stickyScene.kill(true);
    }

    this.timeline = new gsap.timeline();

    this._correctTopOffset();
    this._stick();
    this._bindEvents();
    this._handleAnchors();
    this._runSmoothScrollOverlayMenu();
  }

  set() {
    this.$controls = this.$el.find('.header__controls');
    this.$stickyHeader = this.$el.filter('.js-header-sticky');
    this.$adminBar = $('#wpadminbar');
    this.$burger = $('#js-burger');
    this.$curtain = $('#js-header-curtain');
    this.$curtainTransition = $('#js-header-curtain-transition');
    this.$overlay = $('.header__wrapper-overlay-menu');
    this.burgerOpenClass = 'header__burger_opened';
    this.$headerColumns = this.$el.find('.header__col');
    this.$headerLeft = this.$el.find('.header__col-left');
    this.$overlayWidgets = this.$el.find('.header__wrapper-overlay-widgets');
    this.$allLinksOverlay = this.$el.find('.menu-overlay a');
    this.$allLinksClassic = this.$el.find('.menu a');
    this.$divider = this.$el.find('.header__wrapper-overlay-widgets__border');

    // Menu
    this.$menuOverlay = this.$overlay.find('.js-menu-overlay');
    this.$menuLinks = this.$overlay.find('.menu-overlay > li > a');
    this.selectedClass = 'selected';
    this.openClass = 'opened';

    // Submenu
    this.$submenu = this.$overlay.find('.menu-overlay .sub-menu');
    this.$submenuButton = $('#js-submenu-back');
    this.$submenuOpeners = this.$overlay.find('.menu-item-has-children > a');
    this.$submenuLinks = this.$submenu.find('> li > a');

    // Sticky
    this.stickyScene = undefined;
    this.stickyClass = 'header_sticky';

    // Scrollbar
    this.SB = undefined;

    gsap.fromTo(this.$headerColumns, {
      immediateRender: true,
      autoAlpha: 0,
    },
      {
      delay: window.$pagePreloader.length ? 2.4 : 0,
      autoAlpha: 1,
      stagger: 0.2,
      duration: 0.6
    });

    this.setMenu();
  }

  setBurger(open = false) {
    if (open) {
      this.$el.addClass(this.openClass);
      this.$burger.addClass(this.burgerOpenClass);
    } else {
      this.$el.removeClass(this.openClass);
      this.$burger.removeClass(this.burgerOpenClass);
    }
  }

  setMenu() {

    if (this.$overlay.length) {
      gsap.set(this.$overlay, {
        autoAlpha: 0,
        display: 'none'
      });
    }

    if (this.$submenu.length) {
      gsap.set(this.$submenu, {
        autoAlpha: 0
      });
    }

    if (this.$submenuButton.length) {
      gsap.set(this.$submenuButton, {
        autoAlpha: 0
      });
    }

    if (this.$divider.length) {
      gsap.set(this.$divider, {
        scaleX: 0
      });
    }

    this.$submenu.removeClass(this.openClass);
    this.$el.removeClass(this.openClass);
    this.$burger.removeClass(this.burgerOpenClass);

    if (this.$menuLinks.length) {
      gsap.effects.hideLines(this.$menuLinks, {
        autoAlpha: 1,
        y: '-100%',
        duration: 0,
      });
    }

    if (this.$submenuLinks.length) {
      gsap.effects.hideLines(this.$submenuLinks, {
        autoAlpha: 1,
        y: '-100%',
        duration: 0,
      });
    }

    if (this.$overlayWidgets.length) {
      gsap.effects.hideLines(this.$overlayWidgets, {
        autoAlpha: 1,
        y: this._isMediumScreen() ? '-100%' : '100%',
        duration: 0
      });
    }

    if (this.$curtain.length) {
      gsap.set(this.$curtain, {
        display: 'none',
        autoAlpha: 0
      });
    }

    if (typeof this.SB !== 'undefined') {
      this.SB.scrollTop = 0;
    }
  }

  openMenu() {
    return this.timeline
      .clear()
      .set(this.$curtain, {
        display: 'block',
      })
      .setCurtain(this.$curtain, {
        background: this.overlayBackground,
        y: '100%'
      })
      .set(this.$overlay, {
        autoAlpha: 1,
        display: 'flex'
      })
      .add([() => {
        this._setTransition(true);
        this._unstick();
      }])
      .set(this.$adminBar, {
        position: 'fixed',
      })
      .to(this.$headerLeft, {
        duration: 1.2,
        x: 30,
        autoAlpha: 0,
        ease: 'expo.inOut'
      }, 'start')
      .moveCurtain(this.$curtain, {
        duration: 1.2,
        y: '0%',
      }, 'start')
      .add(() => {
        this.$el.addClass(this.openClass);
      }, '-=0.6')
      .add([
        gsap.effects.animateLines(this.$menuLinks, {
          stagger: {
            amount: 0.2,
            from: 'end'
          },
          duration: 1.2,
          ease: 'power4.out'
        }),
        gsap.effects.animateLines(this.$overlayWidgets, {
          stagger: {
            amount: 0.2,
            from: this._isMediumScreen() ? 'end' : 'start'
          },
          duration: 1.2,
          ease: 'power4.out'
        }),
        gsap.to(this.$divider, {
          scaleX: 1,
          transformOrigin: 'center center',
          duration: 1.2,
          ease: 'expo.inOut'
        })
      ], '-=0.6')
      .add(() => {
        this._setTransition(false);
      }, '-=0.6')
      .timeScale(window.theme.animations.timeScale.overlayMenuOpen || 1);
  }

  closeMenu(force = false, cb) {

    if (!this.$el.hasClass(this.openClass) && !force) {
      return this.timeline;
    }

    const
      $submenuLinksCurrent = this.$submenu.filter(`.${this.openClass}`).find(this.$submenuLinks);

    return this.timeline
      .clear()
      .add(() => {
        this._setTransition(true);
        this._stick();

        if (typeof window.SB !== 'undefined' && window.SB.offset.y >= 1) {
          this.$stickyHeader.addClass(this.stickyClass);
        }
      })
      .set(this.$adminBar, {
        clearProps: 'position'
      })
      .to(this.$headerLeft, {
        duration: 1.2,
        x: 0,
        autoAlpha: 1,
        ease: 'expo.inOut'
      }, 'start')
      .to(this.$submenuButton, {
        x: -10,
        autoAlpha: 0,
        duration: 0.3,
        ease: 'expo.inOut'
      }, 'start')
      .moveCurtain(this.$curtain, {
        duration: 1.2,
        y: '-100%',
        curve: 'bottom'
      }, 'start')
      .add(() => {
        this.$el.removeClass(this.openClass);
      }, '-=0.9')
      .add([
        gsap.effects.hideLines([$submenuLinksCurrent, this.$menuLinks, this.$overlayWidgets], {
          stagger: {
            amount: 0,
            from: 'end'
          },
          y: '100%',
          duration: 0.6,
        }),
        gsap.to(this.$divider, {
          scaleX: 0,
          transformOrigin: 'center center',
          duration: 0.6,
          ease: 'expo.inOut'
        })
      ], 'start')
      .add(() => {
        if (typeof cb === 'function') {
          cb();
        }
        this.$el.attr('data-arts-header-animation', '');
      }, '-=0.3')
      .add(() => {
        this.setMenu();
      })
      .timeScale(window.theme.animations.timeScale.overlayMenuClose || 1);
  }

  closeMenuTransition(force = false) {

    if (!this.$el.hasClass(this.openClass) && !force) {
      return this.timeline;
    }

    const
      $submenuLinksCurrent = this.$submenu.filter(`.${this.openClass}`).find(this.$submenuLinks);

    return this.timeline
      .clear()
      .add(() => {
        this._setTransition(true);
        // Scroll.restoreScrollTop();
        this._stick();

        if (typeof window.SB !== 'undefined' && window.SB.offset.y >= 1) {
          this.$stickyHeader.addClass(this.stickyClass);
        }
      })
      .to(this.$headerLeft, {
        duration: 1.2,
        x: 0,
        autoAlpha: 1,
        ease: 'expo.inOut',
        clearProps: 'transform'
      }, 'start')
      .to(this.$submenuButton, {
        x: -10,
        autoAlpha: 0,
        duration: 0.3,
        ease: 'expo.inOut'
      }, 'start')
      .add(() => {
        this.$el.removeClass(this.openClass);
      }, '-=0.9')
      .add([
        gsap.effects.hideLines([$submenuLinksCurrent, this.$menuLinks, this.$overlayWidgets], {
          stagger: {
            amount: 0,
            from: 'end'
          },
          y: '100%',
          duration: 0.6,
      }),
        gsap.to(this.$divider, {
          scaleX: 0,
          transformOrigin: 'center center',
          duration: 0.6,
          ease: 'expo.inOut'
        })
      ], 'start')
      .add(() => {
        this.$el.attr('data-arts-header-animation', '');
      }, '-=0.3')
      .add(() => {
        this.setMenu();
      });
  }

  _bindEvents() {
    const self = this;

    if (this.$adminBar.length && this.$stickyHeader.length) {
      window.$window.on('resize', window.rhye.functions.debounce(() => {
        this._correctTopOffset();
      }, 250));
    }

    if (this.$burger.length) {
      this.$burger.off('click').on('click', (e) => {
        e.preventDefault();

        if (this._isInTransition()) {
          return;
        }

        if (this.$burger.hasClass(this.burgerOpenClass)) {
          this.closeMenu();
          this.$burger.removeClass(this.burgerOpenClass);
        } else {
          this.openMenu();
          this.$burger.addClass(this.burgerOpenClass);
        }
      });
    }

    if (this.$submenuOpeners.length) {
      this.$submenuOpeners.on('click', function (e) {
        e.preventDefault();

        if (self._isInTransition()) {
          return;
        }

        const
          $el = $(this),
          $currentMenu = $el.parents('ul'),
          $submenu = $el.next('.sub-menu');

        $el.addClass(self.linkSelectedClass);

        self._openSubmenu({
          submenu: $submenu,
          currentMenu: $currentMenu
        });
      });
    }

    if (this.$submenuButton.length) {
      this.$submenuButton.on('click', (e) => {
        e.preventDefault();

        if (self._isInTransition()) {
          return;
        }

        const $submenu = this.$submenu.filter(`.${this.openClass}`),
          $prevMenu = $submenu.parent('li').parent('ul');

        self._closeSubmenu({
          submenu: $submenu,
          currentMenu: $prevMenu
        });
      });
    }

    window.$window
      .on('arts/barba/transition/start', () => {
        this.$controls.addClass('pointer-events-none');
        this._unstick();
      })
      .on('arts/barba/transition/end', () => {
        this.$controls.removeClass('pointer-events-none');
      });
  }

  isOverlayOpened() {
    return this.$el.hasClass(this.openClass);
  }

  _isMediumScreen() {
    return true; //window.Modernizr.mq('(max-width: 991px)');
  }

  _isInTransition() {
    return this.$el.attr('data-arts-header-animation') === 'intransition';
  }

  _setTransition(inTransition = true) {
    return this.$el.attr('data-arts-header-animation', inTransition ? 'intransition' : '');
  }

  _correctTopOffset() {
    this.$adminBar = $('#wpadminbar');
    const top = this.$adminBar.length ? this.$adminBar.height() : 0;

    if (this.$stickyHeader.length && top > 0) {
      gsap.to(this.$el, {
        duration: 0.6,
        top
      });
    }
  }

  _stick() {
    if (!this.$stickyHeader.length) {
      return;
    }

    this.stickyScene = ScrollTrigger.create({
      trigger: window.$pageContent,
      start: `top+=1px top`,
      scrub: true,
      once: false,
      onToggle: ({ isActive }) => {
        if (isActive) {
          this.$stickyHeader.addClass([this.stickyTheme, this.stickyClass].join(' '));
        } else {
          this.$stickyHeader.removeClass([this.stickyTheme, this.stickyClass].join(' '));
        }
      }
    });
  }

  _unstick() {
    if (!this.$stickyHeader.length || !this.stickyScene) {
      return;
    }

    this.stickyScene.kill(true);
    this.stickyScene = undefined;
    this.$stickyHeader.removeClass([this.stickyTheme, this.stickyClass].join(' '));
  }

  _openSubmenu({
    submenu,
    currentMenu
  }) {
    const
      $currentLinks = currentMenu.find('> li > a .menu-overlay__item-wrapper'),
      $submenuLinks = submenu.find('> li > a .menu-overlay__item-wrapper');

    this.timeline
      .clear()
      .add(() => {
        this._setTransition(true);
        this.$submenu.removeClass(this.openClass);
        submenu.not(this.$menuOverlay).addClass(this.openClass);

        if (this.$submenu.hasClass(this.openClass)) {
          gsap.to(this.$submenuButton, {
            autoAlpha: 1,
            x: 0,
            duration: 0.3
          });

          if (this._isMediumScreen()) {
            gsap.effects.hideLines(this.$overlayWidgets, {
              stagger: {
                amount: 0.1,
                from: 'end'
              },
              y: '100%',
              duration: 1.2,
              ease: 'power4.out',
            });
            gsap.to(this.$divider, {
              scaleX: 0,
              transformOrigin: 'center center',
              duration: 0.6,
              ease: 'expo.inOut'
            });
          }
        } else {
          gsap.to(this.$submenuButton, {
            autoAlpha: 0,
            x: -10,
            duration: 0.3
          });

          gsap.effects.animateLines(this.$overlayWidgets, {
            stagger: {
              amount: 0.2,
              from: 'end'
            },
            duration: 1.2,
            ease: 'power4.out',
          });
        }
      })
      .set(submenu, {
        autoAlpha: 1,
        zIndex: 100
      })
      .add(gsap.effects.hideLines($currentLinks, {
        stagger: {
          amount: 0.2,
          from: 'end'
        },
        y: '100%',
        duration: 1.2,
        ease: 'power4.out'
      }))
      .add(gsap.effects.animateLines($submenuLinks, {
        stagger: {
          amount: 0.2,
          from: 'end'
        },
        duration: 1.2,
        ease: 'power4.out'
      }), '-=1.0')
      .add(() => {
        this.$menuLinks.removeClass(this.openClass);
        this._setTransition(false);
      }, '-=0.6')
      .timeScale(1.25);
  }

  _closeSubmenu({
    submenu,
    currentMenu
  }) {
    const
      $currentLinks = currentMenu.find('> li > a .menu-overlay__item-wrapper'),
      $submenuLinks = submenu.find('> li > a .menu-overlay__item-wrapper');

    this.timeline
      .clear()
      .add(() => {
        this._setTransition(true);
        this.$submenu.removeClass(this.openClass);
        currentMenu.not(this.$menuOverlay).addClass(this.openClass);

        if (this.$submenu.hasClass(this.openClass)) {
          gsap.to(this.$submenuButton, {
            autoAlpha: 1,
            x: 0,
            duration: 0.3
          });

          if (this._isMediumScreen()) {
            gsap.effects.hideLines(this.$overlayWidgets, {
              stagger: {
                amount: 0.1,
                from: 'start'
              },
              y: '100%',
              duration: 1.2,
              ease: 'power4.out',
            });
          }
        } else {
          gsap.to(this.$submenuButton, {
            autoAlpha: 0,
            x: -10,
            duration: 0.3
          });

          gsap.effects.animateLines(this.$overlayWidgets, {
            stagger: {
              amount: 0.2,
              from: 'start'
            },
            duration: 1.2,
            ease: 'power4.out',
          });

          gsap.to(this.$divider, {
            scaleX: 1,
            transformOrigin: 'center center',
            duration: 1.2,
            ease: 'expo.inOut'
          });
        }
      })
      .set(submenu, {
        zIndex: -1
      })
      .add(gsap.effects.animateLines($currentLinks, {
        y: '100%',
        duration: 0
      }), 'start')
      .add(gsap.effects.hideLines($submenuLinks, {
        stagger: {
          amount: 0.1,
          from: 'start'
        },
        y: '-100%',
        duration: 1.2,
        ease: 'power4.out'
      }))
      .add(
        gsap.effects.animateLines($currentLinks, {
          stagger: {
            amount: 0.2,
            from: 'start'
          },
          duration: 1.2,
          ease: 'power4.out'
        }), '-=1.0')
      .set(submenu, {
        autoAlpha: 0,
      })
      .add(() => {
        this._setTransition(false);
      }, '-=0.6')
      .timeScale(1.25);
  }

  _handleAnchors() {

    const self = this;

    // overlay anchor links
    this.$allLinksOverlay.filter('a[href*="#"]:not([href="#"]):not([href*="#elementor-action"])').each(function () {
      const
        $current = $(this),
        url = $current.attr('href');

      self._scrollToAnchorFromMenu({
        element: $current,
        url,
        menu: 'overlay'
      });
    });

    // regular menu anchor links
    this.$allLinksClassic.filter('a[href*="#"]:not([href="#"]):not([href*="#elementor-action"])').each(function () {
      const
        $current = $(this),
        url = $current.attr('href');

      self._scrollToAnchorFromMenu({
        element: $current,
        url,
        menu: 'classic'
      });
    });

  }

  _scrollToAnchorFromMenu({
    element,
    url,
    menu = 'classic'
  }) {
    if (!url || !element) {
      return;
    }

    const filteredUrl = url.substring(url.indexOf('#'));

    try {
      if (filteredUrl.length) {
        const $el = window.$pageWrapper.find(filteredUrl);

        if ($el.length) {
          element.on('click', (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (menu === 'classic') {
              window.rhye.components.Scroll.scrollTo({
                y: $el.offset().top - this.$el.innerHeight(),
                duration: 800
              });
            }

            if (menu === 'overlay') {
              this.closeMenu(false, () => {
                window.rhye.components.Scroll.scrollTo({
                  y: $el.offset().top - this.$el.innerHeight(),
                  duration: 800
                });
              });
            }
          });

        } else {
          element.off('click');
        }
      }
    } catch (error) {
      console.error('An error occured while handling menu anchor links: ' + error);
    }

  }

  _runSmoothScrollOverlayMenu() {
    if (!window.Modernizr.touchevents && this.$overlay.hasClass('js-smooth-scroll-container')) {
      window.rhye.components.AssetsManager
        .load(window.theme.assets['smooth-scrolling-js'])
        .then(() => {
          this.SB = window.Scrollbar.init(this.$overlay[0], window.theme.smoothScroll);
        });
    }
  }
}
