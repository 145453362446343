/**
 * Global Vars
 */
window.$document = $(document);
window.$window = $(window);
window.$body = $('body');
window.$html = $('html');
window.$spinner = $('#js-spinner');
window.$barbaWrapper = $('[data-barba="wrapper"]');
window.$pageHeader = $('#page-header');
window.$pageWrapper = $('#page-wrapper');
window.$pageContent = $('.page-wrapper__content');
window.$pagePreloader = $('#js-preloader');
window.PagePreloader = new Preloader({
	scope: window.$document,
	target: window.$pagePreloader,
	curtain: {
		element: $('#js-page-transition-curtain'),
		background: $('.section-masthead').attr('data-background-color')
	},
	counter: {
		easing: 'power4.out',
		duration: 25,
		start: 0,
		target: 100,
		prefix: '',
		suffix: ''
	}
});

/**
 * Begin Page Load
 */
window.PagePreloader.start();

/**
 * Default Theme Options
 * Used to prevent errors if there is
 * no data provided from backend
 */
if (typeof window.theme === 'undefined') {
	window.theme = {
		ajax: {
			enabled: true,
			preventRules: '', // jQuery selectors of the elements to exclude them from AJAX transitions
			evalInlineContainerScripts: false,
			loadMissingScripts: true,
			loadMissingStyles: true
		},
		animations: {
			triggerHook: 0.85, // more info https://scrollmagic.io/docs/ScrollMagic.Scene.html#triggerHook
			timeScale: {
				onScrollReveal: 1, // on-scroll animations global speed
				overlayMenuOpen: 1, // fullscreen menu open speed
				overlayMenuClose: 1, // fullscreen menu close speed
				preloader: 0.9,
				ajaxFlyingImageTransition: 1,
				ajaxCurtainTransition: 1
			}
		},
		cursorFollower: {
			enabled: true,
			labels: {
				slider: 'Drag'
			},
			factorTrailing: 6,
			animationDuration: 0.25,
			elements: {
				socialItems: true,
				blogPagination:  true
			},
		},
		smoothScroll: { // more info https://github.com/idiotWu/smooth-scrollbar/tree/develop/docs
			enabled: true,
			damping: 0.12,
			renderByPixels: true,
			continuousScrolling: false,
			plugins: {
				edgeEasing: true
			},
		},
		contactForm7: {
			customModals: true
		},
		customJSInit: '',
		updateHeadNodes: '',
		mobileBarFix: {
			enabled: true,
			update: true
		},
		elementor: {
			isEditor: false
		},
		highPerformance: true,
		async: {
			enabled: true,
			assets: [],
			promises: []
		}
	}
}

/**
 * ScrollMagic Setup
 */
// window.SMController = new ScrollMagic.Controller();
// window.SMController.enabled(false); // don't start animations yet
window.SMSceneTriggerHook = window.theme.animations.triggerHook;
window.SMSceneReverse = false;

/**
 * Don't save scroll position
 * after AJAX transition
 */
if ('scrollRestoration' in history) {
	history.scrollRestoration = 'manual';
}


/**
 * Page Load Strategy
 */
document.addEventListener('DOMContentLoaded', (e) => {

	// init on AJAX transition
	if (e.detail) {

		initComponents(e.detail);

	} else { // init on initial page load

		initComponents({
			scope: window.$document,
			scrollToHashElement: false
		});

		initComponentsOnce({
			scope: window.$document
		});

	}

});

/**
 * Init Template Components
 * You can init your custom scripts here
 * in that function
 */
function initComponents({
	scope = window.$document,
	container = window.$pageWrapper,
	scrollToHashElement = true
}) {

	const
		$arrow = scope.find('.js-arrow'),
		$smoothScrollContainer = container.filter('.js-smooth-scroll'),
		$sectionBlog = scope.find('.section-blog.section-grid'),
		$section404 = scope.find('.section-404'),
		$scrollDown = scope.find('[data-arts-scroll-down]'),
		$changeTextHover = scope.find('.js-change-text-hover:not(.js-change-text-hover .js-change-text-hover)'), // exclude nested elements
		$sectionMasthead = scope.find('.section-masthead:not(.d-none):not(.js-cancel-init)'),
		$sectionNavPortfolioPrevNext = scope.find('#page-bottom-nav.section-list');	

	if ($smoothScrollContainer.length) {
		window.rhye.components.AssetsManager
			.load(window.theme.assets['smooth-scrolling-js'])
			.then(() => {
				new window.rhye.components.SmoothScroll({
					target: $smoothScrollContainer,
					adminBar: $('#wpadminbar'),
					absoluteElements: $('[data-arts-scroll-absolute]'), // correct handling of absolute elements OUTSIDE scrolling container
					fixedElements: $('[data-arts-scroll-fixed]') // correct handling of fixed elements INSIDE scrolling container
				});
			});
	}

	if ($sectionMasthead.length) {
		window.rhye.components.AssetsManager.load(window.theme.assets['section-masthead-js'])
			.then(() => {
				new window.rhye.components.SectionMasthead({
					target: $sectionMasthead,
					scope
				});
			});
	}

	if ($scrollDown.length) {
		window.rhye.components.AssetsManager
			.loadScrollDown()
			.then(() => {
				new window.rhye.components.ScrollDown({
					target: scope.find('[data-arts-scroll-down]'),
					scope,
					duration: 0.8
				});
			});
	}

	if ($arrow.length) {
		new window.rhye.components.Arrow({
			target: $arrow
		});
	}

	if ($changeTextHover.length) {
		window.rhye.components.AssetsManager
			.loadChangeTextHover()
			.then(() => {
				new window.rhye.components.ChangeTextHover({
					target: $changeTextHover,
					scope,
					pageIndicator: scope.find('.js-page-indicator'), // fixed page indicator
					triggers: scope.find('.js-page-indicator-trigger'), // elements that triggers the change of page indicator
				});
			});
	}

	if ($sectionBlog.length) {
		if (typeof window.plugin === 'undefined') {
			$sectionBlog.attr('data-arts-os-animation', 'animated');
		} else {
			window.rhye.components.AssetsManager
			.loadMasonryGrid()
			.then(() => {
				new window.rhye.components.SectionGrid({
					target: $sectionBlog,
					scope
				});

				new window.rhye.components.SectionContent({
					target: $sectionBlog,
					scope
				});
			});
		}
	}

	if ($section404.length) {
		if (typeof window.plugin === 'undefined') {
			$section404.attr('data-arts-os-animation', 'animated');
		} else {
			window.rhye.components.AssetsManager
			.loadSectionContent()
			.then(() => {
				new window.rhye.components.SectionContent({
					target: $section404,
					scope
				});
			});
		}
	}

	if ($sectionNavPortfolioPrevNext.length) {
		window.rhye.components.AssetsManager
			.loadSectionList()
			.then(() => {
				new window.rhye.components.SectionList({
					target: $sectionNavPortfolioPrevNext,
					scope
				});
		});
	}

	// mobile bottom bar height fix
	if (window.theme.mobileBarFix.enabled) {
		new MobileBarHeight();
	}

	// floating input fields
	new Form({
		target: scope,
		scope
	});

	// refresh animation triggers
	// for Waypoints library
	if (typeof Waypoint !== 'undefined') {
		Waypoint.refreshAll();
	}

	// custom JS code
	if (window.theme.customJSInit) {
		try {
			window.eval(window.theme.customJSInit);
		} catch (error) {
			console.warn(error);
		}
	}

	// scroll to anchor from URL hash
	if ( scrollToHashElement ) {
		window.rhye.components.Scroll.scrollToAnchorFromHash();
	}

}

/**
 * Init Template Components
 * only once after the initial
 * page load
 */
function initComponentsOnce({
	scope = window.$document,
	container = window.$pageWrapper,
	scrollToHashElement = true
}) {

	const $sectionNavProjects = container.find('.section-nav-projects');

	// Init page header
	if (window.$pageHeader.length) {
		window.theme.Header = new Header({
			target: window.$pageHeader,
			scope
		});
	}

	// Init cursor only on non-touch browsers
	if (window.theme.cursorFollower.enabled && !window.Modernizr.touchevents) {
		let highlightElements;
		let exclusionString = '';

		if (window.theme.cursorFollower.elements.socialItems) {
			exclusionString += ':not(.social__item a)';
		}

		if (window.theme.cursorFollower.elements.blogPagination) {
			exclusionString += ':not(a.page-numbers)';
		}

		highlightElements = `a:not(a[data-arts-cursor])${exclusionString}:not(.section-video__link):not(.no-highlight), button:not(button[data-arts-cursor]), .filter__item, .section-nav-projects__header`;

		if (!window.theme.cursorFollower.elements.sliderDots) {
			highlightElements += ' ,.slider__dot';
		}

		if (!window.theme.cursorFollower.elements.circleArrows) {
			highlightElements += ' ,.js-arrow';
		}

		Promise
			.all([
				window.rhye.components.AssetsManager.load(window.theme.assets['cursor-js']),
				window.rhye.components.AssetsManager.load(window.theme.assets['cursor-css'])
			])
			.then(() => window.PagePreloader.finish())
			.then(() => {
				window.rhye.components.Scroll.scrollToTop();

				new window.rhye.components.Cursor({
					scope: window.$document,
					target: $('#js-cursor'),
					cursorElements: '[data-arts-cursor]',
					highlightElements, // links to highlight
					highlightScale: 1.5, // default highlight scaling
					magneticElements: '[data-arts-cursor-magnetic]', // magnetic elements
					magneticScaleCursorBy: 1.3, // default magnetic scaling
					factorTrailing: window.theme.cursorFollower.factorTrailing,
					animDuration: window.theme.cursorFollower.animationDuration,
				});

				// begin animations
				window.rhye.functions.enableAnimations();
				// scroll to anchor from URL hash
				if (scrollToHashElement) {
					window.rhye.components.Scroll.scrollToAnchorFromHash();
				}
		});
	} else {
		window.PagePreloader.finish().then(() => {
			// scroll to anchor from URL hash
			if ( scrollToHashElement ) {
				window.rhye.components.Scroll.scrollToAnchorFromHash();
			}

			// Begin animations
			window.rhye.functions.enableAnimations();
		});
	}

	// init AJAX navigation
	if (window.theme.ajax.enabled && window.$barbaWrapper.length) {
		window.rhye.components.AssetsManager
			.loadPJAX()
			.then(() => {
				new window.rhye.components.PJAX({
					target: window.$barbaWrapper,
					scope: window.$document
				});
		});
	}

	// Init auto scroll navigation
	if ($sectionNavProjects.length) {
		window.rhye.components.AssetsManager
			.loadSectionNavProjects()
			.then(() => {
				new window.rhye.components.SectionNavProjects({
					target: $sectionNavProjects,
					scope
				});
			});
	}

	// Try to use high performance GPU on dual-GPU systems
	runOnHighPerformanceGPU();
}
