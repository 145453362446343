/* ======================================================================== */
/* 7. runOnHighPerformanceGPU */
/* ======================================================================== */
function runOnHighPerformanceGPU() {
	if (!window.Modernizr.touchevents && window.theme.highPerformance) {
		let element = document.getElementsByTagName('canvas')[0];

		if (typeof element === 'undefined' || element === null) {
			element = document.createElement('canvas');
			element.getContext('webgl', {
				powerPreference: 'high-performance'
			});
		}
	}

}
